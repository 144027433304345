import React,  {useContext} from "react"
import styled from "styled-components"
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import SecondButton from "../Button/SecondButton";
import LineArrowRight from "../Icons/LineArrowRight"
import PhoneIcon from "../Icons/PhoneIcon"
import NoteIcon from "../Icons/NoteIcon"

import { Context } from "../../layouts/context";

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  a {
    margin-right: 15px;
  }
  .btn {
    margin-bottom: 20px;
  }

  @media(max-width: 480px) {
    flex-wrap: wrap;
  }
`

const ProductButtons = () => {
	const [popupContext, setPopupContext] = useContext(Context);
	return(
		<ButtonsWrap>
      <a href="tel:3867556449" aria-label="(386) 755-6449"><SecondButton text="(386) 755-6449" preIcon={<PhoneIcon fill="#fff" />} /></a>
      <div onClick={() => setPopupContext("block")} aria-hidden="true">
        <PrimaryLinkButton text="Get a quote" preIcon={<NoteIcon />} icon={<LineArrowRight />} />
      </div>
    </ButtonsWrap>
	)
}

export default ProductButtons