import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios'

import PrimaryButton from "../Button/PrimaryButton";
import LineArrowRight from "../Icons/LineArrowRight"

const FormWrap = styled.form`
	padding: 40px 30px;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  margin-bottom: 72px;

  .title {
    font-size: 26px;
    font-family: "Eina03-Bold";
    line-height: 24px;
    margin-bottom: 29px;
  }

  input {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1) !important;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
    padding: 16px 20px 19px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  textarea {
    width: 100%;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1) !important;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
    padding: 16px 20px 19px;
    resize: none;
    &:focus {
      outline: none;
    }
  }

`

class GetCustomQuoteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      full_name: '',
      email: '',
      phone: '',
      zipcode: '',
      comment: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const {id, value} = event.target
    this.setState({
      [id]: value
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    const { location } = this.props
    const data = {
      source: location.origin,
			form: "Custom Quote Form For Each Product",
			page: location.pathname,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    }
    axios({
      method: "post",
       url: "https://crm.senseicrm.com/api/create-lead-wordpress",
       data: data,
       headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
     }).then((res) => {
       this.setState({ full_name: '', email: '', phone: '', zipcode: '', comment: ''});
       navigate("/thank-you-custom-quote");
     }).catch((error) => {
         alert(error)
     });
  }
  render() {
    return(
    	<FormWrap id="CustomQuoteForm" onSubmit={this.handleSubmit}>
    	  <h3 className="title">Get Your Custom Quote</h3>
    	  <input type="text" name="full_name" id="full_name" onChange={this.handleChange} placeholder="Full Name" pattern="[A-Za-z\s]+$" required />
        <input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email ID" required />
        <input type="tel" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
        <input type="number" name="zipcode" id="zipcode" onChange={this.handleChange} placeholder="Zip Code" required />
        <textarea rows="3" name="comment" id="comment" onChange={this.handleChange} placeholder="Message" required />
        <button type='submit'>
          <PrimaryButton text="Submit" icon={<LineArrowRight />} />
        </button>
    	</FormWrap>
    );
  }
}

export default GetCustomQuoteForm;